<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_09.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--wifi/images/QW5摇头机_11.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗摇头机--WiFi",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>